import React,{Component} from 'react';
import './NotFound.css';
import Wrapper from '../Wrapper/Wrapper';
import gA from "../../ReactGA";
import {HashLink as Link} from "react-router-hash-link";

class NotFound extends Component {
    componentDidMount() {
        gA("/notfound");
    }

    not_found_to_home() {
        gA("/notfound to home")
    }

    render() {
        let inner = (
            <div className={"text-center"}>
                <h2>Page Not Found</h2>
                <Link className="nav-link" to="/#top" style={{marginBottom:"3rem"}} onClick={this.not_found_to_home}><h5>Click here to head to the home page!</h5></Link>
            </div>

        );

        return <Wrapper inner={inner}/>
    }
};

export default NotFound;