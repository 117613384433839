import React from 'react';
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class DiningHall extends React.Component {
    componentDidMount () {
        gA("/facilities/DiningHall");
    }

    inner = (
        <div className="text-center">
            <h3 id={'diningHall'}><strong>Kitchen Dining at Rippling Waters</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/0TMtE8M3650?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/iuyhP3rNKgg?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/HWg1T5876HI?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>$300.00 per day</h4>
                    <p>Attached to the Conference Center/Tabernacle is a very nice air condition/heated, stainless steel
                        commercial grade kitchen. The kitchen includes a large (8) burner (2) oven stove, separate
                        commercial roasting oven, commercial sized food warmer, walk in cooler and freezer, (2) deep
                        freezers, large professional mixer, and plenty of countertop, utility carts, and basic kitchen
                        utensils to allow for the preparation of meals that could easily feed your army. The kitchen also
                        has newly tiled floors that are easy to clean and keep clean, adding to the safety of your
                        event.
                    </p>
                    <p>The dining hall, which is attached to the kitchen, offers (2) large eating areas with plenty of
                        tables and chairs available for use as you see fit for up to 125 people. This area is also air
                        conditioned and heated. The dining hall also has large restrooms that can add just a little more
                        comfort for your guest.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};