import React from 'react';
import './FacilitiesNav.css';
import FacilitiesNavPiece from "./FacilitiesNavPiece";
import BathHouses from "../../../static/CabinRoundHouseCabinBathHouse.webp";
import DeluxeCabins from "../../../static/newCabinMainroom.webp";
import RusticCabins from "../../../static/TypicalCabinBeddingChart.webp";
import McCormick from "../../../static/RubyMcCormickBeddingDiagram.webp";
import CottageDorm from "../../../static/CottageDormBeddingDiagram.webp";
import RoundHouse from "../../../static/round_house.webp";

const FacilitiesNav = () => (
    <div className={"text-center justify-content-md-center row"}>
        <FacilitiesNavPiece to={"/facilities/BathHouses#top"} name={"Bath-houses"} img={BathHouses}/>
        <FacilitiesNavPiece to={"/facilities/DeluxeCabins#top"} name={"Deluxe Family Cabins"} img={DeluxeCabins}/>

        {/*"http://www.youtube.com/embed/BwuJe4AKv94?rel=0"*/}
        {/*"http://www.youtube.com/embed/uJh6neWlduQ?rel=0"*/}
        <FacilitiesNavPiece to={"/facilities/RusticCabins#top"} name={"Rustic Cabins"} img={RusticCabins}/>

        {/*"http://www.youtube.com/embed/18GnfYQfci8?rel=0"*/}
        {/*"http://www.youtube.com/embed/GE2xR5-DK08?rel=0"*/}
        {/*"http://www.youtube.com/embed/kwZErmSSUJU?rel=0"*/}
        {/*"http://www.youtube.com/embed/GGqWJMRJOQ0?rel=0"*/}
        <FacilitiesNavPiece to={"/facilities/MccormickCottage#top"} name={"Ruby McCormick Cottage"} img={McCormick}/>

        {/*"http://www.youtube.com/embed/Upnqbxuqh7k?rel=0"*/}
        <FacilitiesNavPiece to={"/facilities/RoundHouse#top"} name={"Round House"} img={RoundHouse}/>

        {/*"http://www.youtube.com/embed/JWu7OI52yvo?rel=0"*/}
        {/*"http://www.youtube.com/embed/V1FVPCdAliI?rel=0"*/}
        {/*"http://www.youtube.com/embed/JrnHD5tFPtI?rel=0"*/}
        {/*"http://www.youtube.com/embed/VuhxsJFTk9E?rel=0"*/}
        {/*"http://www.youtube.com/embed/urBYqX7L-9U?rel=0"*/}
        <FacilitiesNavPiece to={"/facilities/CottageDorm#top"} name={"Cottage Dorm"} img={CottageDorm}/>

        {/*"http://www.youtube.com/embed/omfMPlWZ0_M?rel=0"*/}
        {/*"http://www.youtube.com/embed/sYKozOEOT4Q?rel=0"*/}
        {/*"http://www.youtube.com/embed/1mRHVRclki0?rel=0"*/}
        {/*"http://www.youtube.com/embed/t3OIfu4U8ow?rel=0"*/}
        <FacilitiesNavPiece
            to={"/facilities/ConferenceCenter#top"}
            name={"Conference Center"}
            youtube={"https://www.youtube.com/embed/omfMPlWZ0_M?rel=0"}
        />

        {/*"http://www.youtube.com/embed/cPPRUdK1X4U?rel=0"*/}
        {/*"http://www.youtube.com/embed/JDvbZy0KTyc?rel=0"*/}
        {/*"http://www.youtube.com/embed/6-K9fhrRk2Y?rel=0"*/}
        {/*"http://www.youtube.com/embed/QujgnYA-8u4?rel=0"*/}
        <FacilitiesNavPiece
            to={"/facilities/Chapel#top"}
            name={"Chapel"}
            youtube={"https://www.youtube.com/embed/6-K9fhrRk2Y?rel=0"}
        />

        {/*"http://www.youtube.com/embed/0TMtE8M3650?rel=0"*/}
        {/*"http://www.youtube.com/embed/iuyhP3rNKgg?rel=0"*/}
        {/*"http://www.youtube.com/embed/HWg1T5876HI?rel=0"*/}
        <FacilitiesNavPiece
            to={"/facilities/DiningHall#top"}
            name={"Dining Hall"}
            youtube={"https://www.youtube.com/embed/0TMtE8M3650?rel=0"}
        />
        <FacilitiesNavPiece to={"/facilities/Apartments#top"} name={"Apartment"} />
        <FacilitiesNavPiece to={"/facilities/BannerHouse#top"} name={"Banner House"} />
        <FacilitiesNavPiece to={"/facilities/LakesidePavilion#top"} name={"Lakeside Pavilion"} />
    </div>
);

export default FacilitiesNav;