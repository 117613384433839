import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function gA(path) {
    var firebaseConfig = {
        apiKey: "AIzaSyB46E5pnrVxlWEY8aU9-sP_aL6CVMvvDiI",
        authDomain: "ripplingwaters-23a8b.firebaseapp.com",
        projectId: "ripplingwaters-23a8b",
        storageBucket: "ripplingwaters-23a8b.appspot.com",
        messagingSenderId: "21378114377",
        appId: "1:21378114377:web:3f7f3caa74f66e153bb098",
        measurementId: "G-VLLR1GXHPX"
    };
    
    var app;
    if (getApps().length === 0) {
        app = initializeApp(firebaseConfig);
    } else {
        app = getApp();
    }
    
    const analytics = getAnalytics(app);
    logEvent(analytics, path);
}
