import React from 'react';
import gA from "../../ReactGA";

export default class Ppr extends React.Component {

    ktlClicked() {
        gA('leftForPaperShoot');
    }

    render () {
        return (
            <div className="mt-3">
                <a href="https://photography.mister-life.com/albums/272107-animals" target="_blank" rel="noopener noreferrer"
                   onClick={this.ktlClicked}>
                    <img src="https://res.cloudinary.com/fleetnation/image/private/c_fit,w_1120/fl_no_overflow,g_south,l_text:style_gothic2:%C2%A9%20mister-life,o_20,y_10/fl_no_overflow,g_center,l_watermark4,o_25,y_50/v1720129375/gwjue3adacr4rwbjcvjh.jpg" alt='Animals photos' style={{
                        maxWidth:'100%',
                        height:'380px' }} />
                </a>
            </div>
        );
    }
}