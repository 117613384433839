import React from 'react';
import RoundHouses from "../../../static/round_house.webp";
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class RoundHouse extends React.Component {
    componentDidMount () {
        gA("/facilities/RoundHouse");
    }

    inner = (
        <div className="text-center">
            <h3 id={'roundHouse'}><strong>Round House at Rippling Waters</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="row justify-content-md-center">
                    <div className="col-md-6 align-self-end">
                        <img className="campMap w-75" src={RoundHouses} alt="The Round House"/>
                    </div>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/Upnqbxuqh7k?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>$25.00 per event</h4>
                    <p>The round house is a very unique building. Centered around a very large fire-ring, The covered
                        bleachers protect your guests from the elements while they enjoy a campfire setting for church and
                        youth services, camp hang-out time, storytelling, and more. No camp experience is complete without a
                        campfire night at the roundhouse. Plenty of parking is available directly around the building with
                        lighting and electrical outlets available within. The cabin bath house restrooms are not far. For a
                        very amazing camp experience, be sure to check out the possibilities of the round house.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};