import React from 'react';
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class Chapel extends React.Component {
    componentDidMount () {
        gA("/facilities/Chapel");
    }

    inner = (
        <div className="text-center">
            <h3 id={'chapel'}><strong>Chapel at Rippling Waters</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/6-K9fhrRk2Y"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/cPPRUdK1X4U?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/JDvbZy0KTyc?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/QujgnYA-8u4?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>$200 per day</h4>
                    <p>Named for the Memorial Church of God which donated the furnishings, Memorial Chapel is a
                        full-functioning mini-chapel on the lake. Quite possibly the most photographed little chapel in the
                        state, the chapel sits beautifully on the bank of White Swan Lake at the campground. With the nicely
                        placed dusk-to-dawn spotlight illuminating the front, Memorial Chapel is visibly gorgeous 24 hours a
                        day.</p>
                    <p>The chapel is used quite extensively; small weddings, vow renewals, prayer meetings, Bible study
                        groups, private prayer time, and is a very romantic place for a marriage proposal. Also, services
                        are held every Sunday during camp season by various pastors from around the state (Memorial
                        Day-Labor Day).</p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};