import React from 'react';
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class Apartments extends React.Component {
    componentDidMount () {
        gA("/facilities/Apartments");
    }

    inner = (
        <div className="text-center">
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h3 id={'apartments'}><strong>Apartment at Rippling Waters</strong></h3>
                    <h4>$75.00 per night or $125 per night for both rooms</h4>
                    <p>The basement of the Nora Hunter Lodge has (2) air conditioned and heated apartment type rooms. Each
                        room has a full bath with a stand-up shower unit, full-sized bed and ample sitting room. Apartment 2
                        has a small living room area as well. The apartments share a common kitchen which includes a full
                        size refrigerator, apartment sized oven, cookware, utensils, and coffee pot.</p>
                    <p>The apartments are great for the quiet getaway experience without the camper, cabin, or tent type of
                        roughing it. Rent them either alone or with a group in the lodge upstairs.</p>
                </div>
            </div>
        </div>
    );
    render() {return <Wrapper inner={this.inner} />}
};
