import React from 'react';
import {HashLink as Link} from "react-router-hash-link";

const FacilitiesNavPiece = (props) => {
    if (props.img) {
        return (
            <div className="col-md-6 align-self-end">
                <Link to={props.to}>
                    <img src={props.img} className={"w-100 facilityNavImg"} alt={props.name}/>
                </Link>
                <Link to={props.to}>
                    <h5 className={"facilityLink"}>{props.name}</h5>
                </Link>
            </div>
        )
    } else if (props.youtube) {
        return (
            <div className="col-md-6 align-self-end">
                <iframe className="w-100" height="300" src={props.youtube}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                </iframe>
                <Link to={props.to}>
                    <h5 className={"facilityLink"}>{props.name}</h5>
                </Link>
            </div>
        )
    } else {
        return (
            <div className="col-md-6 align-self-end">
                <Link to={props.to}>
                    <h5 className={"facilityLink"}>{props.name}</h5>
                </Link>
            </div>
        )
    }
}

export default FacilitiesNavPiece