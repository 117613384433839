import React from 'react';
import './Contact.css';
import Wrapper from '../Wrapper/Wrapper';
import ContactForm from './ContactForm/ContactForm';
import gA from "../../ReactGA";

export default class Contact extends React.Component {
    componentDidMount () {
        gA("/contact");
    }

    inner = (
        <div>
            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-9">
                    <h2>Contact Us at Rippling Waters</h2>
                    <p><strong>Office:</strong> (304)988-2607</p>
                    <p>2771 Middle Fork Road</p>
                    <p>Kenna, WV</p>
                    <p>25248</p>
                    <p><strong>Management:</strong></p>
                    <ContactForm />
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner}/>}
};