import React from 'react';
import './Wrapper.css';
import Ktl from "../Ads/Ktl";
import Ppr from "../Ads/PprShot";

const Wrapper = ({inner}) => {
    return (
        <div className="main-content">
            <div className="d-none d-sm-block">
                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <div className="container">
                            <div className="jumbotron bg-light">
                                {inner}
                                <div className="row justify-content-center topSpacer">
                                    <div className="col-12 justify-content-center">
                                        <p className="text-center">Support this website by clicking the pictures below or going to <a href="https://photography.mister-life.com"  target="_blank" rel="noopener noreferrer" style={{color: "gold"}}>photography.mister-life.com</a>.</p>
                                    </div>
                                    <div className="col-6 d-flex justify-content-center ktl-mtn">
                                        <Ppr/>
                                    </div>
                                    <div className="col-6 d-flex justify-content-center ktl-mtn">
                                        <Ktl/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-sm-none">
                <div className="jumbotron jumbotron bg-light">
                    {inner}
                    <div className="col topSpacer">
                        <div className="d-flex justify-content-center ktl-mtn">
                            <Ppr/>
                        </div>
                        <div className="d-flex justify-content-center ktl-mtn">
                            <Ktl/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wrapper;
