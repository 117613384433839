import * as React from "react";
import './ContactForm.css';
import{ init, send } from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import gA from "../../../ReactGA";

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            contact: 'phone',
            comments: '',
            comments2: '',
        };

        this.recaptchaRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
        if(name === "captcha") {
            console.log(value);
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const recaptchaValue = this.recaptchaRef.current.getValue();

        if(recaptchaValue !== null && recaptchaValue !== "") {
            gA('form_submission');
            this.submission(e);
        } else {
            gA('form_submission_failed');
            window.alert("Please complete the captcha");
        }
    }

    submission(e) {
        const { firstName, lastName, email, phone, contact, comments, comments2 } = this.state
        const subject = "Inquiry from website";
        let templateParams = {
            from_name: email,
            first_name: firstName,
            last_name: lastName,
            to_name: 'ripplingwaterscamp@gmail.com',
            subject: subject,
            message: comments !== "" && comments != null? comments: comments2,
            phone: phone,
            contact: contact
        }

        init('user_wuXdSK1djUNrP5UaO2pj1');
        send(
            'default_service',
            'template_5vv27mu',
            templateParams
        ).then(function(response) {
            window.alert("Thanks for the inquiry, " + firstName + ". You'll hear from us shortly.");
        }, function(error) {
            window.alert("Sorry we couldn't process that right now, try again later.")
        });

        this.resetForm();
    }

    resetForm() {
        this.setState({
            firstName: '',
            lastName: '',
            comments: '',
            comments2: '',
            phone: '',
            email: '',
            contact: 'phone'
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                <label className="label-width">
                    First name:
                    <input required className="spacer spec-width" type="text" name="firstName" value={this.state.firstName} onChange={this.handleChange}/>
                </label>
                <label className="label-width">
                    Last name:
                    <input required className="spec-width" type="text" name="lastName" value={this.state.lastName} onChange={this.handleChange}/>
                </label>
                <br />
                <label className="label-width">
                    Phone number:
                    <input required className="spacer spec-width" type="tel" name="phone" value={this.state.phone} onChange={this.handleChange}/>
                </label>
                <label className="label-width">
                    Email:
                    <input required className="spec-width" type="email" name="email" value={this.state.email} onChange={this.handleChange}/>
                </label>
                <br />
                <label className="label-width">
                    Preferred Contact:
                    <select required name="contact" className="spec-width" value={this.state.contact} onChange={this.handleChange}>
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                    </select>
                </label>
                <br />
                <label className="label-width d-none d-md-block">
                    Comments or questions:
                    <input type="text" name="comments" className="comment-width" value={this.state.comments} onChange={this.handleChange}/>
                </label>
                <label className="label-width d-md-none">
                    Comments or questions:
                    <input type="text" name="comments2" className="spec-width" value={this.state.comments2} onChange={this.handleChange}/>
                </label>
                <br />
                <ReCAPTCHA className="g-recaptcha" sitekey="6Le2I4YaAAAAAAVq7F0NSwtu4pCMAw7xEpjM7Wkk" name="captcha" ref={this.recaptchaRef}/>
                <br />
                <input type="submit" value="Submit"/>
            </form>
        );
    }
}

export default ContactForm