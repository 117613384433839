import React from 'react';
import CottageDormImg from '../../../static/CottageDormBeddingDiagram.webp';
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class CottageDorm extends React.Component {
    componentDidMount () {
        gA("/facilities/CottageDorm");
    }

    inner = (
        <div className="text-center">
            <h3 id={'cottageDorm'}><strong>Cottage Dorm at Rippling Waters</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="row justify-content-md-center">
                    <div className="col-md-6 align-self-end">
                        <img className="campMap w-75" src={CottageDormImg} alt="Cottage Dorm"/>
                    </div>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/JWu7OI52yvo?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/V1FVPCdAliI?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/JrnHD5tFPtI?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/VuhxsJFTk9E?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/urBYqX7L-9U?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>No pets allowed in the Cottage Dorm.</h4>
                    <h4>$100.00 per room, per night (2 rooms minimum)</h4>
                    <p>The Cottage Dorm is our most popular family get-away lodge. It features (4) large bedrooms, (sleeping
                        approximately 8 each) and (2) large bathrooms. Plenty of room for that long desired family reunion
                        or large family get-away. For the use of co-ed camps, the layout of (2) bedrooms and a bath on each
                        side of the building allows for the ease of room segregation as needed.
                    </p>
                    <p>The building also offers a very large fully functioning kitchen. All of the utensils, cookware,
                        accessories, and even the coffee pot are included; all you need to bring is your food. The
                        appliances are all full sized, conveniently located, and include an oven, microwave, and
                        refrigerator. Separating the kitchen from the sitting area is an enormous dining room table that can
                        seat possibly the entire family for a picture perfect holiday meal. After the meal, the family can
                        relax in front of a fire.
                    </p>
                    <p>The sitting area is furnished with (2) large leather sectional couches that wrap around the large
                        open-face fireplace. The firewood is supplied by the campground, so build a nice fire and simply
                        enjoy the comfort and warmth of the fire and your gathering. But you do not just need to stay
                        inside.
                    </p>
                    <p>The cottage also has a very nice yard with an outdoor fire ring to allow for some campfire time;
                        making smores, telling stories, enjoying the peace and quiet of God’s country. Close by, are swing
                        sets and a nice play area for the kids, (2) volleyball courts, hiking and biking areas, and more.
                        The cottage is an amazing building that can help make your overnight event spectacular. And, of
                        course, fishing, paddle boats, paintball, and zip line are all also close by.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};