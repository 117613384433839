import React from 'react';
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class BannerHouse extends React.Component {
    componentDidMount () {
        gA("/facilities/BannerHouse");
    }

    inner = (
        <div className="text-center">
            <h3 id={'bannerHouse'}><strong>Banner House at Rippling Waters</strong></h3>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>$200 per day or $300 for two days</h4>
                    <p>The Banner house is a very nice covered regulation sized basketball court, but that is not the limits
                        of this building. With creative decoration and well placed seating, the Banner house has been used
                        for some very unique and absolutely beautiful weddings. The shelter offers and small, very limited
                        prep room (sink and counter-top), along with male and female restrooms, lighting, electrical
                        outlets, and room for approximately 100 people. Located on top of the knoll at the camp driveway
                        entrance, the banner house has a nice view of the ponds, Memorial Chapel, and even the field below.
                        There is plenty of parking, yard space, and potential with this building. Check it out when you are
                        planning a wedding, basketball game, covered corn-hole tourney, picnic, reception, covered rummage
                        sale, or whatever; it is a very nice and versatile building.
                    </p>
                </div>
            </div>
        </div>
    )

    render() {return <Wrapper inner={this.inner} />}
};
