import React from 'react';
import '../Facilities.css';
import DeluxeCabin from "../../../static/newCabinMainroom.webp";
import DeluxeCabin2 from "../../../static/newCabinMainroom2.webp";
import Bedroom from "../../../static/newCabinBedroon1.webp";
import Bathroom from "../../../static/newCabinBathroom.webp";
import Porch from "../../../static/newCabinPorch.webp";
import gA from "../../../ReactGA";
import Wrapper from "../../Wrapper/Wrapper";

export default class DeluxeCabins extends React.Component {
    componentDidMount () {
        gA("/facilities/DeluxeCabins");
    }

    inner = (
        <div className="text-center">
            <h3 id={'deluxeCabins'}><strong>Deluxe Family Cabins</strong></h3>
            <div className="justify-content-md-center row">
                <div className="col-md-6 align-self-end bottomSpacer">
                    <img className="campMap w-75" src={DeluxeCabin} alt="Deluxe Cabin Main Room"/>
                </div>
                <div className="col-md-6 align-self-end bottomSpacer">
                    <img className="campMap w-75" src={DeluxeCabin2} alt="Deluxe Cabin Main Room"/>
                </div>
                <div className="col-md-6 align-self-end bottomSpacer">
                    <img className="campMap w-75" src={Bedroom} alt="Deluxe Cabin Main Room"/>
                </div>
                <div className="col-md-6 align-self-end bottomSpacer">
                    <img className="campMap w-75" src={Bathroom} alt="Deluxe Cabin Main Room"/>
                </div>
                <div className="col-md-6 align-self-end bottomSpacer">
                    <img className="campMap w-75" src={Porch} alt="Deluxe Cabin Main Room"/>
                </div>
            </div>

            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>No pets allowed in the deluxe cabins.</h4>
                    <h4>$125 per night M-Th</h4>
                    <h4>$150 per night F-Su</h4>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};