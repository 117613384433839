import React from 'react';
import './MissionStatement.css';

const MissionStatement = () => (
    <div className="text-center">
        <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
                <h2>Mission Statement</h2>
                <p>
                    Rippling Waters Church Of God Campground is owned and operated by the Ministries of the Church of God in West Virginia. Our mission is to not only provide ministry opportunities to the Church of God, but also to provide a location for groups, not affiliated with the Church of God, to carry out their ministries or activities. Our goal is to provide a wholesome, family-oriented place for persons to enjoy nature, spend quality time with family and friends, and experience a place where these values are priority.
                </p>
            </div>
            <div className="col-sm-1"></div>
        </div>
    </div>
);

export default MissionStatement;