import React from 'react';
import './Info.css';
import Wrapper from '../Wrapper/Wrapper';
import MissionStatement from "./MissionStatement/MissionStatement";
import NonProfit from "./NonProfit/NonProfit";
import gA from "../../ReactGA";


export default class Info extends React.Component {
    componentDidMount () {
        gA("/info");
    }

    linkClicked() {
        gA('mySite');
    }

    inner = (
        <div>
            <MissionStatement />
            <NonProfit />
            <div className={"text-center myInfo"}>
                <p>Designed and created by</p>
                <p>
                    <a className="facilityLink"
                        href={'https://lifessoftware.com'}
                        target={'_blank'}
                        rel={'noreferrer'}
                        onClick={this.linkClicked}
                    >
                        Lifes Software
                    </a>
                </p>
            </div>
        </div>
    );

    render () {
        return <Wrapper inner={this.inner}/>
    }
};