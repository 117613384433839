import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './Nav.css';
import gA from "../../ReactGA";

const Nav = () => {
    function facebookLinkClicked() {
        gA('leftForFacebook')
    }

    function scrollToDirections() {
        gA("/home/directions");
    }

    function scrollToActivities() {
        gA("/home/activities");
    }

    function scrollToCampOptions() {
        gA("/home/campOptions");
    }

    function scrolltoHomeTop() {
        gA("/home/top");
    }

    return (
        <header>
            <nav className="navbar fixed-top navbar-expand-lg navbar-light">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div id="navbarSupportedContent" className="collapse navbar-collapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link scroll={el => el.scrollIntoView({behavior: 'smooth'})}
                                      onClick={scrolltoHomeTop}
                                      className="nav-link" to="/#top">
                                    <h5>Home</h5>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'center'})}
                                      onClick={scrollToCampOptions}
                                      className="nav-link d-none d-md-block" to="/#campOptions">
                                    <h5>Camp Options</h5>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'center'})}
                                      onClick={scrollToActivities}
                                      className="nav-link d-none d-md-block" to="/#activities">
                                    <h5>Activities</h5>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'center'})}
                                      onClick={scrollToDirections}
                                      className="nav-link d-none d-md-block" to="/#campMaps">
                                    <h5>Directions</h5>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact#top"><h5>Contact Us</h5></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/facilities#top"><h5>Facilities</h5></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/info#top"><h5>Other Info</h5></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="nav-item">
                        <a className="nav-link" rel="noopener noreferrer" target="_blank"
                           href="https://www.facebook.com/Rippling-Waters-Campground-337474424163"
                           onClick={facebookLinkClicked}>
                            <h4>
                                <i className="fab fa-facebook-f"></i>
                                acebook
                            </h4>
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Nav;