import React from 'react';
import BathHouse from "../../../static/CabinRoundHouseCabinBathHouse.webp";
import CampGroundMap from "../../../static/MainCampLayout.webp";
import gA from "../../../ReactGA";
import Wrapper from "../../Wrapper/Wrapper";

export default class BathHouses extends React.Component {
    componentDidMount () {
        gA("/facilities/BathHouses");
    }

    inner = (
        <div className="text-center">
            <h3 id={'bathHouses'}><strong>Bath-houses</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="col-md-6 align-self-end">
                    <img className="campMap w-75" src={CampGroundMap} alt="Cabin Bath House"/>
                </div>
                <div className="col-md-6 align-self-end">
                    <img className="campMap w-75" src={BathHouse} alt="Cabin Bath House"/>
                </div>
            </div>

            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <p>Currently the camp has (5) fully function bath-houses located around the grounds. Our staff keeps
                        these clean and functional throughout camping season. All of them, except in cases of emergency, are
                        open between the months of April through September; at which time, the staff begins winterization
                        procedures on all but one heated bath-house which located at Sleepy hollow (see map).
                    </p>
                    <p>Referring to the camp map, find the bath-house closest to your site for information. Horseshoe
                        (seasonal), Lakeside Pavilion (seasonal), Cedar Knoll (seasonal) bath-houses have (1) each toilet,
                        shower, and sink per side (M&F). The heated Sleepy Hollow bath-house has (2) showers and toilets
                        along with (1) sink per side (M&F). The cabin bath-house (seasonal) offers (4) showers and sinks per
                        side (M&F), (2) urinals and (3) toilets (M), (5) toilets (f), along with plenty of sitting and
                        changing area.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};