import React from 'react';
import {
    BrowserRouter,
    Route,
    Routes as Switch
} from 'react-router-dom';
import NotFound from '../NotFound/NotFound';
import Nav from '../Nav/Nav';
import Home from '../Home/Home';
import Contact from '../Contact/Contact';
import Facilities from '../Facilities/Facilities';
import Info from '../Info/Info';
import Apartments from "../Facilities/Apartments/Apartments";
import BannerHouse from "../Facilities/BannerHouse/BannerHouse";
import BathHouses from "../Facilities/BathHouses/BathHouses";
import Chapel from "../Facilities/Chapel/Chapel";
import ConferenceCenter from "../Facilities/ConferenceCenter/ConferenceCenter";
import CottageDorm from "../Facilities/CottageDorm/CottageDorm";
import DeluxeCabins from "../Facilities/DeluxeCabins/DeluxeCabins";
import LakesidePavilion from "../Facilities/LakesidePavilion/LakesidePavilion";
import McCormickCottage from "../Facilities/McCormickCottage/McCormickCottage";
import RoundHouse from "../Facilities/RoundHouse/RoundHouse";
import RusticCabins from "../Facilities/RusticCabins/RusticCabins";
import DiningHall from "../Facilities/DiningHall/DiningHall";
import gA from "../../ReactGA";

export default class App extends React.Component {
    componentDidMount () {
        gA("/enteringSite");
    }

    render() {
        return (
            <BrowserRouter>
                <div className="jumbotron">
                    <Nav />
                    <Switch>
                        {/*Home page, camping options, camp map, google map*/}
                        <Route exact={true} path="/" element={<Home/>} />
                        {/*Contact form*/}
                        <Route path="/contact" element={<Contact/>} />
                        {/*Facilities, cabins, etc*/}
                        <Route exact={true} path="/facilities" element={<Facilities/>} />
                        {/*Mission statement, non-profit status, */}
                        <Route path="/info" element={<Info/>} />
                        <Route path="/facilities/Apartments" element={<Apartments/>} />
                        <Route path="/facilities/BannerHouse" element={<BannerHouse/>} />
                        <Route path="/facilities/BathHouses" element={<BathHouses/>} />
                        <Route path="/facilities/Chapel" element={<Chapel/>} />
                        <Route path="/facilities/ConferenceCenter" element={<ConferenceCenter/>} />
                        <Route path="/facilities/CottageDorm" element={<CottageDorm/>} />
                        <Route path="/facilities/DeluxeCabins" element={<DeluxeCabins/>} />
                        <Route path="/facilities/DiningHall" element={<DiningHall/>} />
                        <Route path="/facilities/LakesidePavilion" element={<LakesidePavilion/>} />
                        <Route path="/facilities/McCormickCottage" element={<McCormickCottage/>} />
                        <Route path="/facilities/RoundHouse" element={<RoundHouse/>} />
                        <Route path="/facilities/RusticCabins" element={<RusticCabins/>} />
                        <Route path="/RWC/" element={<Home/>} />
                        <Route element={<NotFound/>} />
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}
