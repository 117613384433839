import React from 'react';
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class ConferenceCenter extends React.Component {
    componentDidMount () {
        gA("/facilities/ConferenceCenter");
    }

    inner = (
        <div className="text-center">
            <h3 id={'conferenceCenter'}><strong>Conference Center at Rippling Waters</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/omfMPlWZ0_M?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/sYKozOEOT4Q?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/1mRHVRclki0?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/t3OIfu4U8ow?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>$300.00 per day - Add the kitchen facility for an additional $300</h4>
                    <p>The air conditioned/heated Conference Center, also known as the Tabernacle, is an amazing area with
                        an approximate seating capacity of 600 people. Its large open area offers plenty of seating for
                        numerous events such as wedding receptions, sporting banquets, speaking engagements, large weddings,
                        and more; the building is only limited by your imagination and decoration. At the center of the vast
                        space is the stage offering sitting room for a choir, V.I.P.s, and more.
                    </p>
                    <p>With a close but separate nursery, parents can attend an event knowing they will have a safe place
                        for their children (nursery staff provided by event organizers). The large male and female restrooms
                        help maintain the comfort of your guests. The building is also surrounded by a very large beautiful
                        lawn, allowing you the opportunity to plan fun outdoor activities that may compliment your
                        event.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};