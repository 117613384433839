import React from 'react';
import RubyMcCormick from "../../../static/RubyMcCormickBeddingDiagram.webp";
import gA from "../../../ReactGA";
import Wrapper from "../../Wrapper/Wrapper";

export default class McCormickCottage extends React.Component {
    componentDidMount () {
        gA("/facilities/McCormickCottage");
    }

    inner = (
        <div className="text-center">
            <h3 id={'mccormickCottage'}><strong>Ruby McCormick Cottage</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="row justify-content-md-center">
                    <div className="col-md-6 align-self-end">
                        <img className="campMap w-75" src={RubyMcCormick} alt="Ruby McCormick Cottage"/>
                    </div>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/18GnfYQfci8?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/GE2xR5-DK08?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/kwZErmSSUJU?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/GGqWJMRJOQ0?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
            </div>

            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>No pets allowed in the McCormick Cottage.</h4>
                    <h4>$100.00 per room per night (2 rooms minimum) or $300 for the entire upstairs. Downstairs
                        apartment
                        $75 for one room or $125 for both.
                    </h4>
                    <p>The Ruby McCormick lodge is another nice building that is great for large overnight gatherings,
                        Bible
                        studies, and family reunions. The lodge includes (3) large bedrooms and (2) large bathrooms with
                        a
                        total sleeping capacity of 29-30 people. With the very large covered front deck with rockers and
                        porch swings, a lot of the time here is spent simply enjoying the scenery, wildlife, and
                        quietness
                        of God’s country under protection from the sun and rain.
                    </p>
                    <p>The kitchen is very capable of handling a nice gathering as well. Nicely furnished with a full
                        sized
                        refrigerator and an apartment size oven, all utensils, cookware, and coffee pot, meals are only
                        limited by your ability. The large dining table is conveniently built in the middle of the
                        kitchen,
                        and very mush accessible by anyone.
                    </p>
                    <p>The lodge also has a very nice yard with an outdoor fire ring to allow for some campfire time;
                        making
                        smores, telling stories, enjoying the peace and quiet of God’s country. Close by, are swing sets
                        and
                        a nice play area for the kids, (2) volleyball courts, hiking and biking areas, and more. The
                        cottage
                        is an amazing building that can help make your overnight event spectacular. And, of course,
                        fishing,
                        paddle boats, paintball, and zip line are all also close by.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};