import React from 'react';
import RusticCabin from "../../../static/TypicalCabinBeddingChart.webp";
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class RusticCabins extends React.Component {
    componentDidMount () {
        gA("/facilities/RusticCabins");
    }

    inner = (
        <div className="text-center">
            <h3 id={'rusticCabins'}><strong>Rustic Cabins at Rippling Waters</strong></h3>
            <div className="justify-content-md-center row bottomSpacer">
                <div className="row justify-content-md-center">
                    <div className="col-md-6 align-self-end">
                        <img className="campMap w-75" src={RusticCabin} alt="Typical Cabin Layout"/>
                    </div>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/BwuJe4AKv94?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
                <div className="col-md-6 align-self-end">
                    <iframe className="w-75" height="175" src="https://www.youtube.com/embed/uJh6neWlduQ?rel=0"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>$50.00 per night</h4>
                    <p>Our cabins are nice, but still rustic enough to be considered camping. The cabins sleep seven on (3)
                        twin-sized bunk beds and (1) single twin sized bed. Inside the cabin has a table with chairs, a
                        desk, air conditioning, lights, and electrical outlets. Built in (2) rings of six cabins and (1)
                        ring of five, they were made for church camp and/or family bonding with shared/community fire rings
                        and picnic tables; it is a very nice set-up.
                    </p>
                    <p>We also have one smaller cabin that is designated as the Nurses cabin for various camps. This cabin
                        has a small sink, desk, and only two beds. It is also air condition, lighted, and very close to the
                        bathhouse. The very large and clean cabin bath house is located within walking distance of all of
                        the cabins.
                    </p>
                    <p>The cabins are not only nice for church camps and family reunions, but also for a package deal on
                        paintball. Get all of your buddies together for a Friday of zip-lining , fishing, swimming, and
                        relaxing, then a camping experience in our cabins so you are up and ready first thing Saturday, for
                        a day of paintball; how great is that?! With sleeping for (7) in each cabin, rent a whole ring for a
                        paintball battle of (35-42) people. But you do not need to go that big.
                    </p>
                    <p>
                        Our cabins make a great get-away camping spot for those that just do not want to sleep on the ground
                        when camping. Come on out and rent a cabin for a night of peace and quiet, where all you can hear is
                        the creek, deer, and your loved ones. This is a remarkable place and our cabins offer a great way by
                        which one can enjoy it.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};