import React from 'react';
import Wrapper from "../../Wrapper/Wrapper";
import gA from "../../../ReactGA";

export default class LakesidePavilion extends React.Component {
    componentDidMount () {
        gA("/facilities/LakesidePavilion");
    }

    inner = (
        <div className="text-center">
            <h3 id={'lakesidePavilion'}><strong>Lakeside Pavilion at Rippling Waters</strong></h3>
            <div className="row justify-content-md-center">
                <div className="col-md-9 align-self-end">
                    <h4>$100 per day</h4>
                    <p>Located almost exact opposite of Memorial Chapel on White Swan Lake, the lakeside pavilion is the
                        perfect place for most church picnics, class reunion picnics, family reunions, wedding receptions,
                        and more. With seating for approximately 75 people, plenty of lighting and electrical outlets,
                        attached restrooms, parking and yard, it really does not get much better. The wonderful location
                        right on the water in the middle of the camp allows your guests the ability to ride bikes around the
                        camp roads, fish, ride the paddle boats, walk around the lakes, and simply enjoy God’s country
                        without ever really leaving the picnic. It is truly a great picnic shelter.
                    </p>
                </div>
            </div>
        </div>
    );

    render() {return <Wrapper inner={this.inner} />}
};