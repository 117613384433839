import React, { lazy, Suspense } from 'react';

import Wrapper from '../Wrapper/Wrapper';

import './Home.css';


const Logo = lazy(() => import('./Logo/Logo'));
const SlideShow = lazy(() => import('./Slideshow/Slideshow'));
const CampingOptions = lazy(() => import('./CampingOptions/CampingOptions'));
const Activities = lazy(() => import('./Activities/Activities'));
const CampMaps = lazy(() => import('./CampMaps/CampMaps'));
const GoogleMaps = lazy(() => import('./GoogleMaps/GoogleMaps'));

const renderLoader = () => <p>Loading</p>;

const Home = () => {
    let inner = (
        <div className="row text-center">
            <div className="col-md-1"/>
            <div className="col-md-10">
                <Suspense fallback={renderLoader()}>
                    <Logo />
                </Suspense>
                <Suspense fallback={renderLoader()}>
                    <SlideShow />
                </Suspense>
                <Suspense fallback={renderLoader()}>
                    <CampingOptions />
                </Suspense>
                <Suspense fallback={renderLoader()}>
                    <Activities />
                </Suspense>
                <Suspense fallback={renderLoader()}>
                    <CampMaps />
                </Suspense>
                <Suspense fallback={renderLoader()}>
                    <GoogleMaps />
                </Suspense>
            </div>
            <div className="col-md-1"/>
        </div>
    );

    return <Wrapper inner={inner}/>
};

export default Home;