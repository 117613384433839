import React from 'react';
import './Facilities.css';
import Wrapper from "../Wrapper/Wrapper";
import FacilitiesNav from "./FacilitiesNav/FacilitiesNav";
import gA from "../../ReactGA";

export default class Facilities extends React.Component {
    componentDidMount () {
        gA("/facilities");
    }

    inner = (
        <div className="bottomSpacer text-center">
            <h1 id="activities" className="homeHeader bottomSpacer">The Facilities of Rippling Waters</h1>
            <FacilitiesNav/>
        </div>
    )

    render() {return <Wrapper inner={this.inner}/>}
};