import React from 'react';
import './NonProfit.css';

const NonProfit = () => (
    <div className="text-center non-profit-margin">
        <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
                <h2>Non-Profit Status of Rippling Waters</h2>
                <p>
                    Rippling Waters Church of God Campground is a nonprofit business as we are owned and operated by the Ministries of the Church of God in West Virginia.
                </p>
            </div>
            <div className="col-sm-1"></div>
        </div>
    </div>
);

export default NonProfit;